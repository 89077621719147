
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import { useByoc } from '@/compositions/services/useByoc'
import type { ProviderType } from '@/definitions/services/byoc/types'

export default defineComponent({
  components: {
    TmButton,
    InfoCard,
    InfoCardRow,
  },
  props: {
    provider: {
      type: Object as PropType<ProviderType>,
    },
  },
  setup(props) {
    const { openEditProviderLabelModal, openEditProviderLoginApiModal } = useByoc()
    const editProviderLabel = () => {
      openEditProviderLabelModal(`${props.provider?.label} (${props.provider?.apiKey})`, props.provider?.logo)
    }

    return {
      editProviderLabel,
      openEditProviderLoginApiModal,
    }
  },
})
